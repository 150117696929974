<template>
  <pro-menu-layout :loading="load.loading" :loading-error="load.error">
    hih34543
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";

export default {
  name: "documentViewForm",
  components: { ProMenuLayout },
  data() {
    return {
      load: { loading: false, error: false },
    };
  },
};
</script>
